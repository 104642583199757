var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from '../../translation/Translate';
import { validateSsn } from '../../../functions/validations';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { validateInvitation } from '../../../actions/invitationActions';
import { setLanguage } from '../../../reducers/settingsSlice';
import Alert from '../../common/Alert';
import { isBefore } from 'date-fns';
var useURLSearchParams = function () {
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    return urlParams;
};
var Phase1 = function (_a) {
    var next = _a.next;
    var t = useTranslate();
    var dispatch = useDispatch();
    var params = useURLSearchParams();
    var expiresIn = params.get('expires-in');
    var _b = __read(useState(''), 2), ssn = _b[0], setSsn = _b[1];
    var _c = __read(useState(false), 2), valid = _c[0], setValid = _c[1];
    var _d = __read(useState(false), 2), account = _d[0], setAccount = _d[1];
    var _e = __read(useState(false), 2), isPasswordWrong = _e[0], setIsPasswordWrong = _e[1];
    function submit1() {
        return __awaiter(this, void 0, void 0, function () {
            var queryString, urlParams, id, patientId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryString = window.location.search;
                        urlParams = new URLSearchParams(queryString);
                        id = urlParams.get('id');
                        return [4 /*yield*/, validateInvitation(ssn, id)];
                    case 1:
                        patientId = _a.sent();
                        if (patientId === null) {
                            setIsPasswordWrong(true);
                        }
                        if (patientId !== null) {
                            dispatch(setLanguage(patientId.language));
                            if (ssn && valid && next) {
                                next(ssn, account, patientId);
                            }
                        }
                        else {
                            setIsPasswordWrong(true);
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleSsnChange(s) {
        setValid(validateSsn(s));
        setSsn(s);
    }
    if (expiresIn && isBefore(new Date(expiresIn), new Date())) {
        return _jsx("span", { children: t('invitation.is_expired') });
    }
    return (_jsxs(_Fragment, { children: [_jsx("h2", __assign({ className: "text-xl" }, { children: t('account.welcome') })), _jsxs("div", __assign({ className: "grid gap-4" }, { children: [_jsx("div", { children: _jsxs("label", __assign({ className: "grid gap-y-2 " }, { children: [_jsx("div", __assign({ className: "text-center" }, { children: t('account.enter_ssn') })), _jsxs("div", __assign({ className: "relative block w-64 m-auto" }, { children: [valid && (_jsx("span", __assign({ className: "absolute inset-y-0 left-0 flex items-center pl-2" }, { children: _jsx("div", __assign({ className: "h-5 w-5 text-gray-500 -mt-1" }, { children: _jsx(CheckCircleIcon, { className: "text-green" }) })) }))), _jsx("input", { className: valid ? 'icon-start w-full' : 'w-full', type: "text", defaultValue: ssn, onChange: function (e) { return handleSsnChange(e.target.value); }, placeholder: t('account.ssn') })] }))] })) }), _jsx("div", __assign({ className: "m-auto" }, { children: _jsxs("label", __assign({ className: "py-1 flex gap-2" }, { children: [_jsx("input", { type: "checkbox", value: 1, name: "user_exists", className: "mt-1", onChange: function (e) { return setAccount(e.target.checked); } }), _jsx("span", __assign({ className: "" }, { children: t('account.i_already_have_account') }))] })) }))] })), _jsxs("div", __assign({ className: "text-center flex flex-col gap-3 w-full items-center" }, { children: [_jsx("button", __assign({ disabled: !valid, className: !valid ? 'opacity-25 cursor-not-allowed' : '', onClick: function () { return submit1(); } }, { children: t('actions.continue') })), isPasswordWrong && (_jsx(Alert, __assign({ type: "error" }, { children: t('account.wrong_ssn') })))] }))] }));
};
export default Phase1;
